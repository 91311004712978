import React, { useState } from 'react';
import './chat.css'; 
import WindowIcon from '@mui/icons-material/Window';

function App() {
  async function main(inputText) {
    try {
      const response = await fetch('https://56q4orer80.execute-api.us-east-1.amazonaws.com/Production/AskOpenAIFunction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userInput: inputText }),
      });

      const result = await response.json();
      setAiOutput(result.aiResponse);

      setConversation((prevConversation) => [
        ...prevConversation,
        { role: 'ai', text: result.aiResponse },
      ]);
    } catch (error) {
      console.error(error);
    }
  }

  const [aiOutput, setAiOutput] = useState('');
  const [userInput, setUserInput] = useState('');
  const [conversation, setConversation] = useState([]);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    // Check if the pressed key is Enter
    if (e.key === 'Enter' && userInput.trim() !== '') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setConversation((prevConversation) => [
      ...prevConversation,
      { role: 'user', text: userInput },
    ]);

    main(userInput);
    setUserInput('');
  };

  return (
    <div className="chat-container">
      
      <div className="header">
        <div><WindowIcon></WindowIcon> {'/ vothla-chat'}</div>
      
        {/* <h3 >V-O-T-H-L-A</h3> */}
      </div>
      <div className="chat-history">
        {conversation.map((message, index) => (
          <div
            key={index}
            className={`message ${message.role === 'user' ? 'user' : 'ai'}`}
          >
            {message.text}
          </div>
        ))}
      </div>
      <div className="user-input-container">
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress} 
          placeholder="Type a message..."
        />
        <button
          className="submit-button"
          onClick={handleSubmit}
          disabled={userInput === ''}
        >
          Send
        </button>
      </div>
      <div style={{ color: 'white', backgroundColor: 'black', textAlign: 'center'}}>
      <p>
            © 2023 Vothla |{' '}
            <a href="https://www.linkedin.com/in/imsanthoshkumars/" target="_blank" rel="noopener noreferrer">
              Author
            </a>
          </p>
        </div>
      
    </div>
  );
}

export default App;
